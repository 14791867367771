.auth-workflow .auth-pagelet-container {
	width: 350px;
	margin: 0 auto
}

.auth-workflow .auth-pagelet-container-wide {
	width: 500px;
	margin: 0 auto
}

#auth-alert-window {
	display: none
}

.auth-display-none {
	display: none
}

.auth-pagelet-mobile-container {
	max-width: 400px;
	margin: 0 auto
}

.auth-pagelet-desktop-narrow-container {
	max-width: 350px;
	margin: 0 auto
}

.auth-pagelet-desktop-wide-container {
	max-width: 600px;
	margin: 0 auto
}

.hide {
	display: none
}

.circularLayout {
	padding-left: 55px;
	padding-right: 50px;
	padding-top: 35px;
	max-width: 90%!important;
	margin: 0 auto
}

html.a-mobile.a-input-placeholder label.auth-mobile-label,
label.auth-hidden-label {
	height: 0!important;
	width: 0!important;
	overflow: hidden;
	position: absolute
}

.auth-link-nodecorate {
	color: inherit!important;
	text-decoration: none!important
}

.auth-circular-image {
	position: relative;
	height: 50px;
	width: 50px;
	overflow: hidden;
	border-radius: 50%
}

.auth-text-truncate {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden
}

#auth-captcha-noop-link {
	display: none
}

#auth-captcha-image-container {
	height: 70px;
	width: 200px;
	margin-right: auto;
	margin-left: auto
}

.auth-footer-seperator {
	display: inline-block;
	width: 20px
}

#auth-cookie-warning-message {
	display: none
}

#auth-pv-client-side-error-box,
#auth-pv-client-side-success-box {
	display: none
}

.auth-error-messages {
	color: #000;
	margin: 0
}

.auth-error-messages li {
	list-style: none;
	display: none
}

.auth-inlined-error-message {
	display: none
}

.ap_ango_default .ap_ango_email_elem,
.ap_ango_default .ap_ango_phone_elem {
	display: none
}

.ap_ango_phone .ap_ango_default_elem,
.ap_ango_phone .ap_ango_email_elem {
	display: none
}

.ap_ango_email .ap_ango_default_elem,
.ap_ango_email .ap_ango_phone_elem {
	display: none
}

.auth-visible-password-container {
	display: none;
	width: 100%;
	padding: 0 10px 6px 10px
}

.auth-show-password-enabled.auth-show-password-engaged.auth-show-password-ready .auth-visible-password-container {
	display: block
}

.auth-show-password-enabled.auth-show-password-engaged .auth-visible-password-container.auth-show-password-empty {
	display: none
}

.auth-visible-password {
	white-space: pre
}

.auth-show-password-enabled #auth-show-password-button {
	background-color: rgba(0, 0, 0, .0125);
	border-radius: 2px;
	border: rgba(0, 0, 0, .125) solid 1px;
	height: 36px;
	margin: 0 4px;
	text-align: center;
	width: 36px
}

#auth-show-password-button-container {
	padding: 0;
	display: none
}

.auth-show-password-enabled.auth-show-password-ready #auth-show-password-button-container {
	display: block
}

#auth-show-password-button-icon {
	margin-top: 10px
}

#auth-show-password-checkbox-label {
	display: none
}

.auth-show-password-enabled #auth-show-password-checkbox-label {
	display: block
}

html.a-no-js #auth-show-password-checkbox-container {
	display: none
}

.auth-interactive-dialog {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	display: none;
	background: rgba(0, 0, 0, .8);
	z-index: 100
}

.auth-interactive-dialog #auth-interactive-dialog-container {
	display: table-cell;
	height: 100%;
	vertical-align: middle;
	position: relative;
	text-align: center
}

.auth-interactive-dialog #auth-interactive-dialog-container .auth-interactive-dialog-content {
	display: inline-block
}

.auth-third-party-content {
	text-align: center
}

.auth-wechat-login-button .wechat_button {
	background: #13D71F;
	background: -webkit-gradient(linear, left top, left bottom, from(#13d71f), to(#64d720));
	background: -webkit-linear-gradient(top, #13d71f, #63d71f);
	background: -moz-linear-gradient(top, #13d71f, #63d71f);
	background: -ms-linear-gradient(top, #13d71f, #63d71f);
	background: -o-linear-gradient(top, #13d71f, #63d71f);
	background: linear-gradient(top, #13d71f, #63d71f)
}

.wechat_button_label {
	color: #fff
}

.wechat_button_icon {
	top: 5px!important
}

.a-lt-ie8 .wechat_button_icon {
	top: 0!important
}

.a-lt-ie8 .auth-wechat-login-button .a-button-inner {
	height: 31px
}

.identity-provider-pagelet-wechat-container {
	text-align: center
}

.auth-identity-provider-fallback-button {
	position: fixed;
	width: 100%;
	height: 50px;
	top: 100%;
	left: 50%;
	margin-top: -50px;
	margin-left: -50%
}

.identity-provider-pagelet-wechat-gray-block-hook {
	position: relative;
	margin-bottom: 0
}

.identity-provider-pagelet-wechat-gray-block {
	position: absolute;
	top: 70px;
	left: 50%;
	width: 280px;
	height: 280px;
	margin-left: -140px;
	background: #d3d3d3
}

.auth-contact-verification-spinner {
	position: absolute;
	left: 45%;
	top: 35%
}

.auth-contact-verification-spinner img {
	height: 60%;
	width: 60%
}

.a-tablet body {
	width: 480px;
	margin: auto
}

.a-tablet .a-global-nav-wrapper {
	padding: 24px 15px 0 0;
	background-image: none;
	border-bottom: none;
	text-align: center
}

.a-tablet .a-icon.a-icon-logo {
	width: 7.8rem
}

.auth-clear-icons {
	margin-top: -4rem;
	display: none;
	float: right;
	padding: 1.2rem 1.2rem .2rem 1.2rem;
	transform: scale(1.2, 1.2);
	-webkit-tap-highlight-color: transparent
}

.auth-clear-icon-visible {
	padding-right: 3rem!important
}

input[type=email]::-ms-clear {
	display: none
}

#accordion-signin-signup-page .accordionHeaderMessage {
	font-weight: 400
}

#accordion-signin-signup-page .a-row .a-span12 {
	height: 24px
}

#accordion-signin-signup-page .a-control-row .a-icon-checkbox {
	margin-left: -4px
}

#accordion-signin-signup-page .a-button-text {
	font-size: 13px
}

#accordion-signin-signup-page .auth-password-margin {
	margin-left: -12px
}

#accordion-signin-signup-page #legalTextRow {
	font-size: 11px!important
}

#accordion-signin-signup-page .a-text-right {
	margin-top: 3px
}

#accordion-signin-signup-page #auth-country-picker-container {
	height: 47px
}

#outer-accordion-signin-signup-page .a-accordion .a-accordion-row {
	padding: 12px 5px 9px 55px
}

#outer-accordion-signin-signup-page .a-accordion .a-accordion-inner {
	padding: 0 1.7rem 0
}

#information-box-logo {
	display: block;
	margin: auto;
	margin-top: 40px
}

.information-box-item {
	color: #6C7778;
	display: block;
	margin: auto;
	margin-top: 25px;
	width: 254px
}

#authportal-center-section {
	width: 700px;
	margin: auto
}

#authportal-main-section {
	margin-top: 10px
}

@media (max-width:700px) {
	#authportal-infobox-section {
		display: none
	}
	#authportal-center-section {
		margin: auto;
		width: 350px
	}
}

.auth-inlined-information-message {
	display: inline-block
}

.moa-single-claim-input-field-container {
	display: table;
	width: 100%
}

.moa-single-claim-input-field-container #ap_email {
	display: table-cell
}

.moa-single-claim-input-field-container .country-picker {
	display: table-cell;
	width: 1%;
	white-space: nowrap;
	padding: 2px 0 0 10px;
	text-align: center;
	color: #0066c0;
	vertical-align: middle
}

#country_bottom_sheet_container .ap-no-padding {
	padding: 0
}

#country_bottom_sheet_container #ap-countries-list {
	list-style: none
}

#country_bottom_sheet_container #ap-countries-list li {
	padding: 14px 10px;
	border: 4px solid transparent;
	border-bottom: 1px solid #eaeded
}

#country_bottom_sheet_container #ap-countries-list li.selected {
	border-left: 4px solid #e77600;
	background-color: #f6f6f6
}

.auth-prompt-profile-details {
	padding-left: 10px
}

.auth-prompt-section {
	margin-top: 1px;
	margin-bottom: 15px
}

.auth-prompt-spacing-top-micro {
	margin-top: 5px
}

.auth-prompt-profile-business-name {
	font-weight: 700
}

#auth-auto-verification-failed {
	display: none
}

#auth-fpp-link-top {
	float: right;
	display: none
}

#auth-enter-pwd-to-cont {
	margin-left: 5px
}

.ap_hidden {
	display: none
}

.auth-contact-verification-section {
	position: relative;
	height: 100px
}

.auth-contact-verification-success-message {
	position: absolute;
	bottom: 10px
}

.auth-html-button-inside-link {
	background: 0 0!important;
	border: none;
	padding: 0!important;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	text-decoration: inherit
}