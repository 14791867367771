.loaderNone {
display:none;
}
.storechild {

    padding-top:30px;
}
.owl-carousel .owl-nav button.owl-next, .owl-carousel .owl-nav button.owl-prev, .owl-carousel button.owl-dot {
    color: inherit;
    border: none;
    padding: 0 20px !important;
}

.owl-theme .owl-nav [class*=owl-] {
    display: inline-block;
    cursor: pointer;
    border-radius: 3px;
    background: #e8f2e8 !important;
    padding: 20px;
    font-size: 30px!important;
    font-size: 30px;
    margin: 5px;
}



.btn-buy{

}


.ordertable, .orderinfo {
    background: #fff;
    padding: 20px;
    overflow-x: auto;
}
    .ordertable thead, .orderinfo thead {
        background-image: linear-gradient(to right, #ed6ea0 0%, #ec8c69 100%) !important;
        color: #fff;
    }

.warningold a{
    color:#fff;
}

.alert-warm{

    border:2px dotted red;
    padding:20px;
    font-size:18px;
    text-align:center;
}
.cart-warm {
    font-size: 18px;
    text-transform: uppercase;
    text-align: center;
}

.mob .nav-item, .show-non-desktop .nav-item {
    float: right;
}
.no-book {
    width: 100%;
    text-align: center;
    padding: 40px;
}


.genreList {
    padding: 5px;
    text-align: center;
    transition: all .6s ease;
    border: 1px solid #fff;
    background-color: #fff;
    transition: background 1s ease-out;
    background-size: 400px 1px;
    margin: 5px 0 5px 0;
    box-shadow: 0 2px 8px rgb(18 17 36 / 4%), 0 4px 32px rgb(18 17 36 / 8%);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    height: 60px;
    color: #057200;
    font-size: 14px;
    text-transform:uppercase;
    
}
    .genreList:hover {
        border: 1px solid #ff0072;
        box-shadow: 0px 1px 5px 1px rgba(255,0,114,0.21);
        color: #ff0072;
    }
    .genreListCat a {
        text-decoration: none;
    }
.headcat {
    margin: 0 0 10px 0;
}


.no-book p {
    font-size: 18px;
    text-transform: uppercase;
}

.kode-author .kode-caption, .kode-author-2:before, .kode-author-2 img, .kode-author .kode-caption h4, .kode-author .kode-caption p {
    transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
}
.kode-author {
    float: left;
    width: 100%;
    position: relative;
    
    
}
.kode-author-2:before {
    
    content: "";
    position: absolute;
    left: 20px;
    top: 20px;
    right: 20px;
    bottom: 20px;
    border: solid 2px rgba(255,255,255,0);
}
.kode-author-2 {
    padding-bottom:20px
}
.kode-author img {
    float: left;
    width: 100%;
}


.kode-author-2 .kode-caption {
    width: 100%;
    text-align: left;
    left: auto;
    right: 0px;
    background-color: #057200;
    padding: 10px 5px 10px 5px;
    bottom: 15px;
    color: #fff;
    position: absolute;
}


.kode-author .kode-caption h4 {
    font-size: 12px;
    /* font-weight: bold; */
    color: #ffffff;
    text-transform: uppercase;
    margin: 0px 0px 0px;
}
    .kode-author .kode-caption p {
        text-transform: uppercase;
        margin: 0px 0px 7px 0px;
    }


.ps-block--category {
    margin-bottom: 30px;
    padding: 5px 5px;
    width: 171px;
    text-align: center;
    transition: all .6s ease;
    border: 1px solid #bfbfbf;
    background-color: #ed6ea0;
    background: linear-gradient(to right, #ed6ea0 0%, #ec8c69 100%);
    -webkit-transition: background 1s ease-out;
    -moz-transition: background 1s ease-out;
    -o-transition: background 1s ease-out;
    transition: background 1s ease-out;
    background-size: 400px 1px;
    margin: 0 1.25% 1.25% 0;
    box-shadow: 2px 2px 19px #c1c1c1;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
}

.library {
    border: 1px solid;
    margin: 20px 0;
    padding: 10px;
    text-transform: uppercase;
    text-align: center;
}


    .ps-block--category:hover {
        background-position: -150px;
        border:1px solid #fff;
    }

    .orderinfo {
        background: #fff;
        padding: 20px;
        font-size: 16px
    }
    .orderinfo h3{
        font-size:20px;
    }

    .orderinfo span {
        font-weight:600;


    }
    .ps-block--category p {
        color: #fff;
        font-size: 16px;
        font-weight: 600;
        text-transform: uppercase;
        font-family: 'Segoe UI';
    }

.warningold {
    background-color: #ff0072;
    color:#fff;
    width:100%;
    padding:15px;
    text-align:center;
    font-family:'Segoe UI';
    font-weight:600;
    text-transform:uppercase;
    font-size:18px
}
.ps-block--myaccount {
    text-align: center;
    width: 47%;
    transition: all .4s ease;
    border: 1px solid #bfbfbf;
    background-image: linear-gradient(to right, #ed6ea0 0%, #ec8c69 100%);
    margin-bottom: 30px;
    padding: 20px 10px;
    margin: 12px;
    background-color: #f4f4f4;
    
    margin: 1.25%;
    box-shadow: 2px 2px 19px #c1c1c1;
    margin-top: 40px;
    margin-bottom: 40px;
}

.btn-white {
    box-shadow: 0px 4px 12px 0px #7979794d;
    color: #057200;
    border: none;
    font-weight: 400;
    display: inline-block;
    min-width: 14rem;
    text-decoration: none;
    text-transform: uppercase;
    text-align: center;
    border-radius: 6px;
    width:100%;
    line-height: 4.4rem;
    height: 4.4rem;
    padding: 0 1.7rem;
    background: #fff;
    margin: 0;
    cursor: pointer;
    width: 100%;
    border:1px solid #fff;
    transition: all 500ms cubic-bezier(0.77, 0, 0.175, 1);
}
    .btn-white:hover {
        text-decoration: none;
        border: 1px solid #ff0072;
        color:#ff0072
    }
.ps-block--myaccount p {
    color: #fff;
    font-size: 18px;
    font-weight: 600;
    text-transform: uppercase;
    font-family: 'Segoe UI';
}

    .ps-block--myaccount i{
        color:#fff;
        font-size:36px
    }
    .loaderView {
        width: 100%;
        position: absolute;
        z-index: 99999999;
        height: 100%;
        top: 0;
        background: #ffffffc4;
    }
.loaderIcon {
    text-align: center;
    margin-top: 200px;
}
.react-autosuggest__input--focused {
    outline: none;
}
.icondownload {
    background: #08a201;
    font-size: 9px;
    color: #fff;
    width: 10px;
    color: #fff;
}
.image-gallery {
    background: #dbdbdb;
}
.footer-widget a{
    text-decoration: none !important;
}

.typeship {
    margin: 15px 0 15px 0;
    border: 1px solid #e6e5e5;
    border-radius: 2px;
    box-shadow: 1px 1px 10px #e0e0e0;
    background: #ffffff;
    padding: 20px;
    display: flow-root;
    color: #057400;
}
@media (max-width: 483px){
    .deliveryIcon span {
        display: block;
        margin: 10px;
    }
}

.data-alarm {
    border: 1px solid red;
    margin: 20px 0;
    padding: 10px;
    background: #fff;
    font-size: 14px;
}
.typeship img {
        width: 70px;      
       
        padding: 3px;
        box-shadow:0px 1px 10px #e0e0e0;
        margin-right: 10px;
}
.loader-frame h3 {
    text-align: center;
    text-transform: uppercase;
    margin: 20px 0;
    color: #088b01;
}
.block-genres-lib {
    padding: 10px;
    border-radius: 10px;
    margin: 10px 1px;
    background: #fff;
    box-shadow: 0 2px 8px rgb(18 17 36 / 4%), 0 4px 32px rgb(18 17 36 / 8%);
    text-transform: uppercase;
}
.block-genres-lib a {
    text-decoration: none;
}
    .block-genres-lib a:hover {
        text-decoration: none;
        color: #ff0072;
    }
    .redColorText {
    color: #ff0072;
}
.full-width {
    width: 100%;
}
.owl-carousel {
    border-left: 5px solid #fff;
    border-right: 5px solid #fff;
}
.owlwidth {
    width: 160px !important
}
.book-img {
    height: 300px;
}

.react-autosuggest__input--open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.react-autosuggest__container {
    width: 95%;
    float: left;
}

.react-autosuggest__input {
    width: 100%;
}

.react-autosuggest__suggestions-container {
    display: none;
}

.searchin {
    width: 100%;
    background: #fff;
}
@media (max-width: 1024px ) {
    .header-clean-btn {
        float: left;
        /* top: 10px; */
        background: #fff;
        border: #fff;
        color: #ff0072;
        padding: 12px 15px;
        
    }
    .react-autosuggest__container {
        width: 90%;
        float: left;
    }
}



@media (max-width: 480px ) {
    .header-clean-btn {
        float: left;
        /* top: 10px; */
        background: #fff;
        border: #fff;
        color: #ff0072;
        padding: 12px 15px;
    }

    .react-autosuggest__container {
        width: 80%;
        float: left;
    }
}

.header-clean-btn {
    float: left;
    /* top: 10px; */
    background: #fff;
    border: #fff;
    color: #ff0072;
    padding: 12px 5px
}
.react-autosuggest__suggestions-container--open {
    display: block;
    position: absolute;
    top: 51px;
    width: 100%;
    border: 1px solid #aaa;
    background-color: #fff;
    font-family: Helvetica, sans-serif;
    font-family: Open Sans, Open Sans Hebrew, Arial;
    font-weight: 300;
    font-size: 16px;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    z-index: 2;
    color: #000;
}

.react-autosuggest__suggestions-list {
    margin: 0;
    padding: 0;
    list-style-type: none;
}

.react-autosuggest__suggestion {
    cursor: pointer;
    padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
    background-color: #ddd;
}



.far {
    position: absolute;
    font-size: unset !important;
    left: 42%;
    top: 15%;
    color: #ffffff;
}

.newstext{padding:0 25px}
.newstext img {
    float: left;
    padding: 10px 10px 10px 0
}

.fullwidthbtn{
    width:100% !important;
}
.newstext h3 {
    font-size: 26px;
    font-weight: 600;
    margin-top: 20px
}

* =============================================
Featured Item Container
============================================= */
.tg-featureditm {
    width: 100%;
    float: left;
}

.tg-featureditm figure {
    width: 100%;
    float: left;
    margin: -30px 0 -36px -40px;
}

    .tg-featureditm figure img {
        float: left;
        width: auto;
        display: block;
        max-width: none;
    }

.tg-featureditmcontent {
    width: 100%;
    float: left;
    margin: 77px 0;
    position: relative;
    padding: 0 30% 0 0;
}

    .tg-featureditmcontent .tg-booktitle h3 {
        font-size: 40px;
        font-weight: bold;
        line-height: 43px;
    }

    .tg-featureditmcontent .tg-bookwriter {
        font-size: 18px;
        line-height: 18px;
        padding: 0 0 10px;
    }

    .tg-featureditmcontent .tg-stars span,
    .tg-featureditmcontent .tg-stars {
        width: 110px;
        height: 18px;
        line-height: 18px;
    }

        .tg-featureditmcontent .tg-stars span:after,
        .tg-featureditmcontent .tg-stars:before {
            font-size: 18px;
            line-height: 18px;
        }

        .tg-featureditmcontent .tg-stars span {
            width: 75%;
        }

    .tg-featureditmcontent .tg-bookprice ins,
    .tg-featureditmcontent .tg-bookprice del {
        float: left;
        color: #484848;
        font: bold 40px/40px 'Work Sans', Arial, Helvetica, sans-serif;
    }

.tg-bookprice ins {
    text-decoration: none;
}

.tg-featureditmcontent .tg-bookprice del {
    font-size: 26px;
    font-weight: 500;
}

.tg-priceandbtn {
    top: 0;
    right: 0;
    margin: 0px 0;
    text-align: center;
    position: absolute;
}

    .tg-priceandbtn .tg-bookprice {
        width: auto;
        float: none;
        display: block;
        overflow: hidden;
    }

.block-wrap.promo .paragraph-content img, .promo.order-segments .paragraph-content img {
    margin: 0;
    box-shadow: none;
}


/* =============================================
			Authors Style						
============================================= */
.tg-authorsslider .owl-nav {
    top: -86px;
    right: 15px;
    width: auto;
    float: none;
    position: absolute;
}

.tg-authors {
    width: 100%;
    float: left;
}

.tg-author {
    width: 100%;
    float: left;
    margin: 15px 0;
}

    .tg-author.item {
        padding: 0 15px;
    }

    .tg-author figure {
        margin: 0;
        width: 100%;
        float: left;
        border: 6px solid transparent;
    }

        .tg-author figure a {
            display: block;
        }

            .tg-author figure a img {
                width: 100%;
                height: auto;
                display: block;
            }

.tg-authorcontent {
    width: 100%;
    float: left;
    padding: 10px 0 0;
    text-align: center;
}

    .tg-authorcontent h2 {
        margin: 0;
        font-size: 16px;
        font-weight: 500;
        line-height: 16px;
    }

    .tg-authorcontent span {
        display: block;
        font-size: 14px;
        line-height: 14px;
        padding: 20px 0;
    }

    .tg-authorcontent .tg-socialicons {
        width: 100%;
        float: left;
        text-align: center;
    }

        .tg-authorcontent .tg-socialicons li {
            float: none;
            display: inline-block;
            vertical-align: top;
        }

            .tg-authorcontent .tg-socialicons li a {
                color: #999;
                background: #f7f7f7;
            }

                .tg-authorcontent .tg-socialicons li a i {
                    color: #999;
                }

.tg-author:hover figure {
    border-color: #fff;
    -webkit-box-shadow: 0 0 10px 2px rgba(0,0,0,0.20);
    box-shadow: 0 0 10px 2px rgba(0,0,0,0.20);
}

.tg-author:hover .tg-authorcontent .tg-socialicons li a,
.tg-author:hover .tg-authorcontent .tg-socialicons li a i {
    color: #fff;
}

.tg-author:hover .tg-authorcontent .tg-socialicons li.tg-facebook a {
    background: #3b5999;
}

.tg-author:hover .tg-authorcontent .tg-socialicons li.tg-twitter a {
    background: #55acee;
}

.tg-author:hover .tg-authorcontent .tg-socialicons li.tg-linkedin a {
    background: #0077B5;
}

.img-home {
    background: url(https://tatkniga.ru/img/bg.jpg) no-repeat;
}

.tg-featureditmcontent {
    background: #ffffffed;
    padding: 10px;
}

.tg-themetag {
    background: #ff0072;
    padding: 3px;
    color: #fff;
    font-weight: 600;
    text-transform: uppercase;
    padding: 30px 3px 10px 3px;
}


.btnP, .btnN {
    margin: 2px;
    border-radius: 2px;
    color: #08a201;
}
    .btnP a, .btnN a {
        color: #fff;
    }

.readerViewer {
    margin: 20px;
    padding: 20px;
    -webkit-box-shadow: 0 6px 18px rgba(14,21,47,.1), 0 -2px 6px rgba(14,21,47,.02);
    box-shadow: 0 6px 18px rgba(14,21,47,.1), 0 -2px 6px rgba(14,21,47,.02);
}
.btnHead {
    display: inline-table;
    padding: 10px;
    margin: 10px;
    border: 1px solid #08a201;
    /* background: #ff0072; */
    /* padding: 3px; */
    font-size: 20px;
    text-decoration: none;
    color: #08a201;
}


.title-news{
    text-transform:uppercase;
    font-size:14px;
    font-weight:600;
    font-family:'Segoe UI' ;
    color:#fff;
}
    .title-news a{
        color:#fff !important;
        

    }

.content-news {
    padding: .5rem 1rem;
    margin: 0 0 10px 0;
    background: #f0f0f0;
    box-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: #3bb78f;
    background-image: linear-gradient(315deg, #3bb78f 0%, #0bab64 74%);
    background-color: #057200;
    background-image: linear-gradient(315deg,#057200,#08a201 74%);
    border-radius: 0 0 10px 10px;
    box-shadow: 0px 0px 5px #a5a5a5;

}


    .btnHead a {
        text-decoration: none;
    }
    .btnHead a:hover {
        text-decoration: none !important;
    }
    .btnHead:hover {
        text-decoration: none !important;
    }


.titleHead {
    font-size: 22px;
    font-weight: 600;
    font-family: Segoe UI;
    background: #ff3371;
    display: inline-flex;
    padding: 6px 12px;
    color: #fff;
    margin: 0px;
}
.paddin-zero{
    padding:0px !important;
}
.block-wrap .block-header {
    margin: 0px !important;
    padding-bottom: 0;
}

.block-wrap, .order-segments {
    padding: 0 !important;
}

.head-block, .foot-block, .content-block {
    padding: 0 !important;
}
.sidebarTitle {
    background: #ff3371;
    color: #fff;
}
.sidebarTitle2 {
    background: #ff3371;
    color: #fff;
    padding:14px !important;
}

.item-info h1 {
    font-family: Segoe UI;
    background: #ff3371;
    display: inline-flex;
    padding: 6px 15px !important;
    color: #fff;
    text-shadow: 0 2px 2px rgb(0 0 0 / 50%);
    
    box-shadow: 0 2px 8px rgb(18 17 36 / 4%), 0 4px 2px rgb(18 17 36 / 8%);
}


.item-type {
    font-family: Segoe UI;
    background: #057700;
    display: inline-flex;
    padding: 6px 15px !important;
    color: #fff;
    display: table;
    text-shadow: 0 2px 2px rgb(0 0 0 / 50%);
    margin: 0px 0 15px 0;
    box-shadow: 0 2px 8px rgb(18 17 36 / 4%), 0 4px 2px rgb(18 17 36 / 8%)
}

@media (max-width: 480px) {
    .primary-wrap .search-wrap .header-search-el-wrap .text-input, .react-autosuggest__input {
        padding-left: 2rem !important;
    }
    .tg-featureditm{display:none}
}

@media screen and (min-width:768px) {
    .article-body p,
    .article-body ul {
        font-size: 18px;
        line-height: 28px;
        margin-bottom: 8px;
        font-family: Open Sans, Open Sans Hebrew, Arial;
        text-align: justify;
    }
}
body {
    font-family: Open Sans, Open Sans Hebrew, Arial;
}

.capit {
    text-transform: uppercase
}

.red {
  color: red !important;
}

footer {
    background-color: #f5f5f5;
    padding: 40px 0;
    box-shadow: 0px 0px 20px #9494946e;
}
.bookType {
    background: #ff3371;
    position: absolute;
    bottom: 0;
    color: #fff;
    padding: 5px;
    z-index: 322222;
    width: 100px;
}

.bookTypePartner {
    background: #08a201;
    position: absolute;
    bottom: 30px;
    color: #fff;
    padding: 5px;
    z-index: 322222;
    width: 100px;
}

.bookTypeSales {
    background: #08a201;
    position: absolute;
    top: 0px;
    color: #fff;
    padding: 5px;
    z-index: 322222;
    width: 100px;
}
.author-info a{margin-left:10px}


footer .footer-widget h3 {
    margin-bottom: 15px;
}
footer .contact-link {
  display: inline-block;
  width: 100%;
  color: #333;
}
footer .footer-social ul {
  padding-left: 0;
}
footer .footer-social ul li {
  list-style: none;
  float: left;
  padding: 0 10px;
}
footer .footer-social ul li:first-child {
  padding-left: 0;
}
footer .footer-social ul li a {
  font-size: 20px;
  color: #333;
}
footer .footer-social ul li a:hover {
  color: red;
}
footer .opening-time {
  padding-left: 0;
}
footer .opening-time li {
  list-style: none;
}
footer .opening-time li span {
  float: left;
  padding-right: 10px;
}
footer .opening-time li span .fa-times {
  color: red;
}
footer .opening-time li strong {
  color: red;
}
footer .media-body a {
  color: #333;
}
footer .media-body a:hover {
  color: red;
}
footer .media-body span {
  color: #969696;
}
footer .images-gellary ul {
  padding-left: 0;
}
footer .images-gellary ul li {
  list-style: none;
  float: left;
  margin: 0 3% 2% 0;
  width: 31%;
  position: relative;
}
footer .images-gellary ul li:nth-child(3n) {
  margin: 0 0 1%;
}

.footer h3 {
    font-size: 24px !important;
}
.footer a {
    border-bottom: 0 !important;
}


.footerline {
    background-color: #3bb78f;
    background-image: linear-gradient(315deg, #3bb78f 0%, #0bab64 74%);
    background-color: #057200;
    background-image: linear-gradient(315deg,#057200,#08a201 74%);
    height: 9px;
    margin-bottom: 20px;
}

body {
    /*background: linear-gradient(45deg, rgba(17,153,255,0.1) 40%, rgba(204,34,85,0.1) 80%);*/
    background: rgb(97 187 255 / 10%);
}

.item-pirce {
    color: #ff0072;
    font-size: 20px;
}

.item-title {
    text-transform: uppercase;
    font-weight: 600;
}

.item-total {
    color: #20b078;
    font-weight: 600;
}
.block-wrap-content{
    margin:15px
}



.wrap-input100 {
    width: 100%;
    position: relative;
    background-color: #f7f7f7;
    border: 1px solid #e6e6e6;
    border-radius: 10px
}

.input100 {
    font-family: Poppins-Regular;
    color: #333;
    line-height: 1.2;
    font-size: 18px;
    display: block;
    width: 100%;
    background: 0 0;
    height: 60px;
    padding: 0 20px
}

.focus-input100 {
    position: absolute;
    display: block;
    width: calc(100% + 2px);
    height: calc(100% + 2px);
    top: -1px;
    left: -1px;
    pointer-events: none;
    border: 1px solid #fc00ff;
    border-radius: 10px;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
    -webkit-transform: scaleX(1.1) scaleY(1.3);
    -moz-transform: scaleX(1.1) scaleY(1.3);
    -ms-transform: scaleX(1.1) scaleY(1.3);
    -o-transform: scaleX(1.1) scaleY(1.3);
    transform: scaleX(1.1) scaleY(1.3)
}

.input100:focus + .focus-input100 {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
}

.eff-focus-selection {
    visibility: visible;
    opacity: 1;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -ms-transform: scale(1);
    -o-transform: scale(1);
    transform: scale(1)
}

.container-login100-form-btn {
    width: 100%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    flex-wrap: wrap
}

.login100-form-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
    width: 100%;
    height: 60px;
    background-color: #333;
    border-radius: 10px;
    font-family: Poppins-Medium;
    font-size: 16px;
    color: #fff;
    line-height: 1.2;
    -webkit-transition: all .4s;
    -o-transition: all .4s;
    -moz-transition: all .4s;
    transition: all .4s;
    position: relative;
    z-index: 1
}

    .login100-form-btn::before {
        content: "";
        display: block;
        position: absolute;
        z-index: -1;
        width: 100%;
        height: 100%;
        border-radius: 10px;
        top: 0;
        left: 0;
        background: #a64bf4;
        background: -webkit-linear-gradient(45deg, #00dbde, #fc00ff);
        background: -o-linear-gradient(45deg, #00dbde, #fc00ff);
        background: -moz-linear-gradient(45deg, #00dbde, #fc00ff);
        background: linear-gradient(45deg, #00dbde, #fc00ff);
        opacity: 0;
        -webkit-transition: all .4s;
        -o-transition: all .4s;
        -moz-transition: all .4s;
        transition: all .4s
    }

    .login100-form-btn:hover:before {
        opacity: 1
    }

.validate-input {
    position: relative
}

.alert-validate::before {
    content: attr(data-validate);
    position: absolute;
    max-width: 70%;
    background-color: #fff;
    border: 1px solid #c80000;
    border-radius: 2px;
    padding: 4px 25px 5px 10px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 12px;
    pointer-events: none;
    font-family: Poppins-Regular;
    color: #c80000;
    font-size: 14px;
    line-height: 1.4;
    text-align: left;
    visibility: hidden;
    opacity: 0;
    -webkit-transition: opacity .4s;
    -o-transition: opacity .4s;
    -moz-transition: opacity .4s;
    transition: opacity .4s
}

.alert-validate::after {
    content: "\f12a";
    font-family: FontAwesome;
    display: block;
    position: absolute;
    color: #c80000;
    font-size: 18px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
    transform: translateY(-50%);
    right: 18px
}

.alert-validate:hover:before {
    visibility: visible;
    opacity: 1
}

@media(max-width:992px) {
    .alert-validate::before {
        visibility: visible;
        opacity: 1
    }
}

.linegreen {
    margin:20px 0px;
    height: 3px;
    background-color: #3bb78f;
    background-image: linear-gradient(315deg, #3bb78f 0%, #0bab64 74%);
}

.loadDisp, .loadDisp-1-load, .loadDisp-2-load, .loadDisp-3-load, .loadDisp-4-load, .loadDisp-5-load, .loadDisp-6-load, .loadDisp-7-load, .loadDisp-8-load, .loadDisp-9-load, .loadDisp-10-load {
    display: none;
}
.viewDisp, .viewDisp-1-view, .viewDisp-2-view, .viewDisp-3-view, .viewDisp-4-view, .viewDisp-5-view, .viewDisp-6-view, .viewDisp-7-view, .viewDisp-8-view, .viewDisp-9-view, .viewDisp-10 {
    display: block;
}

.mini-basket .item-total {
    color:#fff !important;
}
.paymenttype {
    font-size: 18px;
    color: #088b01;
    text-transform: uppercase;
    font-family: 'Segoe UI';
}

.shippingmethod {
    font-size: 18px;
    padding: 15px 0 15px 0;
    color: #484848;
    text-transform: uppercase;
    font-family: 'Segoe UI';
    /*background: #fafafa;
    border: 1px solid #e8e8e8;*/
}

.shippingmethod p {
    margin: 0px;
    padding: 3px;
}


@media screen and (min-width:768px) {
    .deliveryicon {
        position: absolute;
        top: 0px;
        left: 500px;
        width: 290px;
        opacity: 90%;
    }


}

@media screen and (min-width:992px) {

    .mobilemenu {
        display: none;
    }
}


    @media (max-width: 480px) {
        .deliveryicon {
            position: absolute;
            top: 20px;
            left: 250px;
            width: 190px;
            opacity: 50%;
        }
    }



    @media (max-width: 991px) {
        .mobilemenu {
            background: #fff;
            position: fixed;
            bottom: 0px;
            width: 100%;
            z-index: 1000;
            border-top: 6px solid #3bb78f;
        }
        .soc {
             padding: 10px;
             text-align: center; 
        }

            .mobilemenu span {
                display: block;
            }

        .navbtnmobile {
            float: left;
            display: block;
            padding: 10px 5px;
            margin: 0 auto;
            text-align: center;
            font-size: 0px;
            text-transform: uppercase;
            font-family: 'Segoe UI';
        }

        .secondary-header {
            width: 99%
        }
    }

.footer-widget {
    margin-top: 20px
}


.mainbox {
    background-color: #95c2de;
    margin: auto;
    min-height: 400px;
    min-width: 200px;
    max-width: 100%;
    max-height: 100%;
    position: relative;
    text-align: center;
}

.err {
    color: #ffffff;
    font-family: 'Segoe UI';
    font-size: 11rem;
    text-align: center;
    padding: 50px 20px 20px 20px;
}

.far {
    position: absolute;
    font-size: 8.5rem;
    left: 42%;
    top: 15%;
    color: #ffffff;
}

.err2 {
    color: #ffffff;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position: absolute;
    left: 68%;
    top: 8%;
}

.msg {
    text-align: center;
    font-family: 'Segoe UI';
    font-size: 4.5rem;
    padding: 50px;
    color: #fff;
    text-shadow: 1px 1px 1px #91919100, 1px 2px 1px #f9f9f991, 1px 3px 1px #92929296, 1px 4px 1px #ffffff, 1px 6px 6px rgb(152 152 152 / 40%), 1px 14px 15px rgba(16,16,16,0.2), 1px 10px 20px rgb(16 16 16 / 0%);
}

    .msg p {
        font-size: 2rem;
    }





.Demo__some-network {
    vertical-align: top;
    display: inline-block;
    margin-right: 10px;
    text-align: center;
}

.Demo__some-network__share-count {
    display: inline-flex;
    justify-content: center;
    white-space: nowrap;
    overflow: visible;
    width: 0;
    margin-top: 3px;
    font-size: 12px;
}

.Demo__some-network__share-button {
    cursor: pointer;
}

    .Demo__some-network__share-button:hover:not(:active) {
        opacity: 0.75;
    }

.Demo__some-network__custom-icon {
    width: 32px;
    height: 32px;
}

.errNone{
    display:none;
}
.errData {
    
    border: 2px solid #ff0072;
    color: #ff0072;
    font-size: 18px;
    width:100%;
    padding:15px;
    text-align:center;
    margin-bottom:10px;
}
.pay .mainbox{padding-top:50px;
    background:#fff;
}

.slide-in-fwd-center {
    -webkit-animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: slide-in-fwd-center 0.4s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-in-fwd-center {
    0% {
        -webkit-transform: translateZ(-1400px);
        transform: translateZ(-1400px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}

@keyframes slide-in-fwd-center {
    0% {
        -webkit-transform: translateZ(-1400px);
        transform: translateZ(-1400px);
        opacity: 0;
    }

    100% {
        -webkit-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 1;
    }
}










@-webkit-keyframes swal2-show {
    0% {
        -webkit-transform: scale(.7);
        transform: scale(.7)
    }

    45% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05)
    }

    80% {
        -webkit-transform: scale(.95);
        transform: scale(.95)
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@keyframes swal2-show {
    0% {
        -webkit-transform: scale(.7);
        transform: scale(.7)
    }

    45% {
        -webkit-transform: scale(1.05);
        transform: scale(1.05)
    }

    80% {
        -webkit-transform: scale(.95);
        transform: scale(.95)
    }

    100% {
        -webkit-transform: scale(1);
        transform: scale(1)
    }
}

@-webkit-keyframes swal2-hide {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }

    100% {
        -webkit-transform: scale(.5);
        transform: scale(.5);
        opacity: 0
    }
}

@keyframes swal2-hide {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }

    100% {
        -webkit-transform: scale(.5);
        transform: scale(.5);
        opacity: 0
    }
}

@-webkit-keyframes swal2-animate-success-line-tip {
    0% {
        top: 1.1875em;
        left: .0625em;
        width: 0
    }

    54% {
        top: 1.0625em;
        left: .125em;
        width: 0
    }

    70% {
        top: 2.1875em;
        left: -.375em;
        width: 3.125em
    }

    84% {
        top: 3em;
        left: 1.3125em;
        width: 1.0625em
    }

    100% {
        top: 2.8125em;
        left: .875em;
        width: 1.5625em
    }
}

@keyframes swal2-animate-success-line-tip {
    0% {
        top: 1.1875em;
        left: .0625em;
        width: 0
    }

    54% {
        top: 1.0625em;
        left: .125em;
        width: 0
    }

    70% {
        top: 2.1875em;
        left: -.375em;
        width: 3.125em
    }

    84% {
        top: 3em;
        left: 1.3125em;
        width: 1.0625em
    }

    100% {
        top: 2.8125em;
        left: .875em;
        width: 1.5625em
    }
}

@-webkit-keyframes swal2-animate-success-line-long {
    0% {
        top: 3.375em;
        right: 2.875em;
        width: 0
    }

    65% {
        top: 3.375em;
        right: 2.875em;
        width: 0
    }

    84% {
        top: 2.1875em;
        right: 0;
        width: 3.4375em
    }

    100% {
        top: 2.375em;
        right: .5em;
        width: 2.9375em
    }
}

@keyframes swal2-animate-success-line-long {
    0% {
        top: 3.375em;
        right: 2.875em;
        width: 0
    }

    65% {
        top: 3.375em;
        right: 2.875em;
        width: 0
    }

    84% {
        top: 2.1875em;
        right: 0;
        width: 3.4375em
    }

    100% {
        top: 2.375em;
        right: .5em;
        width: 2.9375em
    }
}

@-webkit-keyframes swal2-rotate-success-circular-line {
    0% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg)
    }

    5% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg)
    }

    12% {
        -webkit-transform: rotate(-405deg);
        transform: rotate(-405deg)
    }

    100% {
        -webkit-transform: rotate(-405deg);
        transform: rotate(-405deg)
    }
}

@keyframes swal2-rotate-success-circular-line {
    0% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg)
    }

    5% {
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg)
    }

    12% {
        -webkit-transform: rotate(-405deg);
        transform: rotate(-405deg)
    }

    100% {
        -webkit-transform: rotate(-405deg);
        transform: rotate(-405deg)
    }
}

@-webkit-keyframes swal2-animate-error-x-mark {
    0% {
        margin-top: 1.625em;
        -webkit-transform: scale(.4);
        transform: scale(.4);
        opacity: 0
    }

    50% {
        margin-top: 1.625em;
        -webkit-transform: scale(.4);
        transform: scale(.4);
        opacity: 0
    }

    80% {
        margin-top: -.375em;
        -webkit-transform: scale(1.15);
        transform: scale(1.15)
    }

    100% {
        margin-top: 0;
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@keyframes swal2-animate-error-x-mark {
    0% {
        margin-top: 1.625em;
        -webkit-transform: scale(.4);
        transform: scale(.4);
        opacity: 0
    }

    50% {
        margin-top: 1.625em;
        -webkit-transform: scale(.4);
        transform: scale(.4);
        opacity: 0
    }

    80% {
        margin-top: -.375em;
        -webkit-transform: scale(1.15);
        transform: scale(1.15)
    }

    100% {
        margin-top: 0;
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1
    }
}

@-webkit-keyframes swal2-animate-error-icon {
    0% {
        -webkit-transform: rotateX(100deg);
        transform: rotateX(100deg);
        opacity: 0
    }

    100% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1
    }
}

@keyframes swal2-animate-error-icon {
    0% {
        -webkit-transform: rotateX(100deg);
        transform: rotateX(100deg);
        opacity: 0
    }

    100% {
        -webkit-transform: rotateX(0);
        transform: rotateX(0);
        opacity: 1
    }
}

body.swal2-toast-shown.swal2-has-input > .swal2-container > .swal2-toast {
    flex-direction: column;
    align-items: stretch
}

    body.swal2-toast-shown.swal2-has-input > .swal2-container > .swal2-toast .swal2-actions {
        flex: 1;
        align-self: stretch;
        justify-content: flex-end;
        height: 2.2em
    }

    body.swal2-toast-shown.swal2-has-input > .swal2-container > .swal2-toast .swal2-loading {
        justify-content: center
    }

    body.swal2-toast-shown.swal2-has-input > .swal2-container > .swal2-toast .swal2-input {
        height: 2em;
        margin: .3125em auto;
        font-size: 1em
    }

    body.swal2-toast-shown.swal2-has-input > .swal2-container > .swal2-toast .swal2-validationerror {
        font-size: 1em
    }

body.swal2-toast-shown > .swal2-container {
    position: fixed;
    background-color: transparent
}

    body.swal2-toast-shown > .swal2-container.swal2-shown {
        background-color: transparent
    }

    body.swal2-toast-shown > .swal2-container.swal2-top {
        top: 0;
        right: auto;
        bottom: auto;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    body.swal2-toast-shown > .swal2-container.swal2-top-end, body.swal2-toast-shown > .swal2-container.swal2-top-right {
        top: 0;
        right: 0;
        bottom: auto;
        left: auto
    }

    body.swal2-toast-shown > .swal2-container.swal2-top-left, body.swal2-toast-shown > .swal2-container.swal2-top-start {
        top: 0;
        right: auto;
        bottom: auto;
        left: 0
    }

    body.swal2-toast-shown > .swal2-container.swal2-center-left, body.swal2-toast-shown > .swal2-container.swal2-center-start {
        top: 50%;
        right: auto;
        bottom: auto;
        left: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%)
    }

    body.swal2-toast-shown > .swal2-container.swal2-center {
        top: 50%;
        right: auto;
        bottom: auto;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%)
    }

    body.swal2-toast-shown > .swal2-container.swal2-center-end, body.swal2-toast-shown > .swal2-container.swal2-center-right {
        top: 50%;
        right: 0;
        bottom: auto;
        left: auto;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%)
    }

    body.swal2-toast-shown > .swal2-container.swal2-bottom-left, body.swal2-toast-shown > .swal2-container.swal2-bottom-start {
        top: auto;
        right: auto;
        bottom: 0;
        left: 0
    }

    body.swal2-toast-shown > .swal2-container.swal2-bottom {
        top: auto;
        right: auto;
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    body.swal2-toast-shown > .swal2-container.swal2-bottom-end, body.swal2-toast-shown > .swal2-container.swal2-bottom-right {
        top: auto;
        right: 0;
        bottom: 0;
        left: auto
    }

.swal2-popup.swal2-toast {
    flex-direction: row;
    align-items: center;
    width: auto;
    padding: .625em;
    box-shadow: 0 0 .625em #d9d9d9;
    overflow-y: hidden
}

    .swal2-popup.swal2-toast .swal2-header {
        flex-direction: row
    }

    .swal2-popup.swal2-toast .swal2-title {
        justify-content: flex-start;
        margin: 0 .6em;
        font-size: 1em
    }

    .swal2-popup.swal2-toast .swal2-close {
        position: initial
    }

    .swal2-popup.swal2-toast .swal2-content {
        justify-content: flex-start;
        font-size: 1em
    }

    .swal2-popup.swal2-toast .swal2-icon {
        width: 2em;
        min-width: 2em;
        height: 2em;
        margin: 0
    }

    .swal2-popup.swal2-toast .swal2-icon-text {
        font-size: 2em;
        font-weight: 700;
        line-height: 1em
    }

    .swal2-popup.swal2-toast .swal2-icon.swal2-success .swal2-success-ring {
        width: 2em;
        height: 2em
    }

    .swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
        top: .875em;
        width: 1.375em
    }

        .swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
            left: .3125em
        }

        .swal2-popup.swal2-toast .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
            right: .3125em
        }

    .swal2-popup.swal2-toast .swal2-actions {
        height: auto;
        margin: 0 .3125em
    }

    .swal2-popup.swal2-toast .swal2-styled {
        margin: 0 .3125em;
        padding: .3125em .625em;
        font-size: 1em
    }

        .swal2-popup.swal2-toast .swal2-styled:focus {
            box-shadow: 0 0 0 .0625em #fff,0 0 0 .125em rgba(50,100,150,.4)
        }

    .swal2-popup.swal2-toast .swal2-success {
        border-color: #a5dc86
    }

        .swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line] {
            position: absolute;
            width: 2em;
            height: 2.8125em;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
            border-radius: 50%
        }

            .swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=left] {
                top: -.25em;
                left: -.9375em;
                -webkit-transform: rotate(-45deg);
                transform: rotate(-45deg);
                -webkit-transform-origin: 2em 2em;
                transform-origin: 2em 2em;
                border-radius: 4em 0 0 4em
            }

            .swal2-popup.swal2-toast .swal2-success [class^=swal2-success-circular-line][class$=right] {
                top: -.25em;
                left: .9375em;
                -webkit-transform-origin: 0 2em;
                transform-origin: 0 2em;
                border-radius: 0 4em 4em 0
            }

        .swal2-popup.swal2-toast .swal2-success .swal2-success-ring {
            width: 2em;
            height: 2em
        }

        .swal2-popup.swal2-toast .swal2-success .swal2-success-fix {
            top: 0;
            left: .4375em;
            width: .4375em;
            height: 2.6875em
        }

        .swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line] {
            height: .3125em
        }

            .swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=tip] {
                top: 1.125em;
                left: .1875em;
                width: .75em
            }

            .swal2-popup.swal2-toast .swal2-success [class^=swal2-success-line][class$=long] {
                top: .9375em;
                right: .1875em;
                width: 1.375em
            }

    .swal2-popup.swal2-toast.swal2-show {
        -webkit-animation: showSweetToast .5s;
        animation: showSweetToast .5s
    }

    .swal2-popup.swal2-toast.swal2-hide {
        -webkit-animation: hideSweetToast .2s forwards;
        animation: hideSweetToast .2s forwards
    }

    .swal2-popup.swal2-toast .swal2-animate-success-icon .swal2-success-line-tip {
        -webkit-animation: animate-toast-success-tip .75s;
        animation: animate-toast-success-tip .75s
    }

    .swal2-popup.swal2-toast .swal2-animate-success-icon .swal2-success-line-long {
        -webkit-animation: animate-toast-success-long .75s;
        animation: animate-toast-success-long .75s
    }

@-webkit-keyframes showSweetToast {
    0% {
        -webkit-transform: translateY(-.625em) rotateZ(2deg);
        transform: translateY(-.625em) rotateZ(2deg);
        opacity: 0
    }

    33% {
        -webkit-transform: translateY(0) rotateZ(-2deg);
        transform: translateY(0) rotateZ(-2deg);
        opacity: .5
    }

    66% {
        -webkit-transform: translateY(.3125em) rotateZ(2deg);
        transform: translateY(.3125em) rotateZ(2deg);
        opacity: .7
    }

    100% {
        -webkit-transform: translateY(0) rotateZ(0);
        transform: translateY(0) rotateZ(0);
        opacity: 1
    }
}

@keyframes showSweetToast {
    0% {
        -webkit-transform: translateY(-.625em) rotateZ(2deg);
        transform: translateY(-.625em) rotateZ(2deg);
        opacity: 0
    }

    33% {
        -webkit-transform: translateY(0) rotateZ(-2deg);
        transform: translateY(0) rotateZ(-2deg);
        opacity: .5
    }

    66% {
        -webkit-transform: translateY(.3125em) rotateZ(2deg);
        transform: translateY(.3125em) rotateZ(2deg);
        opacity: .7
    }

    100% {
        -webkit-transform: translateY(0) rotateZ(0);
        transform: translateY(0) rotateZ(0);
        opacity: 1
    }
}

@-webkit-keyframes hideSweetToast {
    0% {
        opacity: 1
    }

    33% {
        opacity: .5
    }

    100% {
        -webkit-transform: rotateZ(1deg);
        transform: rotateZ(1deg);
        opacity: 0
    }
}

@keyframes hideSweetToast {
    0% {
        opacity: 1
    }

    33% {
        opacity: .5
    }

    100% {
        -webkit-transform: rotateZ(1deg);
        transform: rotateZ(1deg);
        opacity: 0
    }
}

@-webkit-keyframes animate-toast-success-tip {
    0% {
        top: .5625em;
        left: .0625em;
        width: 0
    }

    54% {
        top: .125em;
        left: .125em;
        width: 0
    }

    70% {
        top: .625em;
        left: -.25em;
        width: 1.625em
    }

    84% {
        top: 1.0625em;
        left: .75em;
        width: .5em
    }

    100% {
        top: 1.125em;
        left: .1875em;
        width: .75em
    }
}

@keyframes animate-toast-success-tip {
    0% {
        top: .5625em;
        left: .0625em;
        width: 0
    }

    54% {
        top: .125em;
        left: .125em;
        width: 0
    }

    70% {
        top: .625em;
        left: -.25em;
        width: 1.625em
    }

    84% {
        top: 1.0625em;
        left: .75em;
        width: .5em
    }

    100% {
        top: 1.125em;
        left: .1875em;
        width: .75em
    }
}

@-webkit-keyframes animate-toast-success-long {
    0% {
        top: 1.625em;
        right: 1.375em;
        width: 0
    }

    65% {
        top: 1.25em;
        right: .9375em;
        width: 0
    }

    84% {
        top: .9375em;
        right: 0;
        width: 1.125em
    }

    100% {
        top: .9375em;
        right: .1875em;
        width: 1.375em
    }
}

@keyframes animate-toast-success-long {
    0% {
        top: 1.625em;
        right: 1.375em;
        width: 0
    }

    65% {
        top: 1.25em;
        right: .9375em;
        width: 0
    }

    84% {
        top: .9375em;
        right: 0;
        width: 1.125em
    }

    100% {
        top: .9375em;
        right: .1875em;
        width: 1.375em
    }
}

body.swal2-shown:not(.swal2-no-backdrop):not(.swal2-toast-shown) {
    overflow-y: hidden
}

body.swal2-height-auto {
    height: auto !important
}

body.swal2-no-backdrop .swal2-shown {
    top: auto;
    right: auto;
    bottom: auto;
    left: auto;
    background-color: transparent
}

    body.swal2-no-backdrop .swal2-shown > .swal2-modal {
        box-shadow: 0 0 10px rgba(0,0,0,.4)
    }

    body.swal2-no-backdrop .swal2-shown.swal2-top {
        top: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    body.swal2-no-backdrop .swal2-shown.swal2-top-left, body.swal2-no-backdrop .swal2-shown.swal2-top-start {
        top: 0;
        left: 0
    }

    body.swal2-no-backdrop .swal2-shown.swal2-top-end, body.swal2-no-backdrop .swal2-shown.swal2-top-right {
        top: 0;
        right: 0
    }

    body.swal2-no-backdrop .swal2-shown.swal2-center {
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%)
    }

    body.swal2-no-backdrop .swal2-shown.swal2-center-left, body.swal2-no-backdrop .swal2-shown.swal2-center-start {
        top: 50%;
        left: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%)
    }

    body.swal2-no-backdrop .swal2-shown.swal2-center-end, body.swal2-no-backdrop .swal2-shown.swal2-center-right {
        top: 50%;
        right: 0;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%)
    }

    body.swal2-no-backdrop .swal2-shown.swal2-bottom {
        bottom: 0;
        left: 50%;
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%)
    }

    body.swal2-no-backdrop .swal2-shown.swal2-bottom-left, body.swal2-no-backdrop .swal2-shown.swal2-bottom-start {
        bottom: 0;
        left: 0
    }

    body.swal2-no-backdrop .swal2-shown.swal2-bottom-end, body.swal2-no-backdrop .swal2-shown.swal2-bottom-right {
        right: 0;
        bottom: 0
    }

.swal2-container {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    background-color: transparent;
    z-index: 1060;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch
}

    .swal2-container.swal2-top {
        align-items: flex-start
    }

    .swal2-container.swal2-top-left, .swal2-container.swal2-top-start {
        align-items: flex-start;
        justify-content: flex-start
    }

    .swal2-container.swal2-top-end, .swal2-container.swal2-top-right {
        align-items: flex-start;
        justify-content: flex-end
    }

    .swal2-container.swal2-center {
        align-items: center
    }

    .swal2-container.swal2-center-left, .swal2-container.swal2-center-start {
        align-items: center;
        justify-content: flex-start
    }

    .swal2-container.swal2-center-end, .swal2-container.swal2-center-right {
        align-items: center;
        justify-content: flex-end
    }

    .swal2-container.swal2-bottom {
        align-items: flex-end
    }

    .swal2-container.swal2-bottom-left, .swal2-container.swal2-bottom-start {
        align-items: flex-end;
        justify-content: flex-start
    }

    .swal2-container.swal2-bottom-end, .swal2-container.swal2-bottom-right {
        align-items: flex-end;
        justify-content: flex-end
    }

    .swal2-container.swal2-grow-fullscreen > .swal2-modal {
        display: flex !important;
        flex: 1;
        align-self: stretch;
        justify-content: center
    }

    .swal2-container.swal2-grow-row > .swal2-modal {
        display: flex !important;
        flex: 1;
        align-content: center;
        justify-content: center
    }

    .swal2-container.swal2-grow-column {
        flex: 1;
        flex-direction: column
    }

        .swal2-container.swal2-grow-column.swal2-bottom, .swal2-container.swal2-grow-column.swal2-center, .swal2-container.swal2-grow-column.swal2-top {
            align-items: center
        }

        .swal2-container.swal2-grow-column.swal2-bottom-left, .swal2-container.swal2-grow-column.swal2-bottom-start, .swal2-container.swal2-grow-column.swal2-center-left, .swal2-container.swal2-grow-column.swal2-center-start, .swal2-container.swal2-grow-column.swal2-top-left, .swal2-container.swal2-grow-column.swal2-top-start {
            align-items: flex-start
        }

        .swal2-container.swal2-grow-column.swal2-bottom-end, .swal2-container.swal2-grow-column.swal2-bottom-right, .swal2-container.swal2-grow-column.swal2-center-end, .swal2-container.swal2-grow-column.swal2-center-right, .swal2-container.swal2-grow-column.swal2-top-end, .swal2-container.swal2-grow-column.swal2-top-right {
            align-items: flex-end
        }

        .swal2-container.swal2-grow-column > .swal2-modal {
            display: flex !important;
            flex: 1;
            align-content: center;
            justify-content: center
        }

    .swal2-container:not(.swal2-top):not(.swal2-top-start):not(.swal2-top-end):not(.swal2-top-left):not(.swal2-top-right):not(.swal2-center-start):not(.swal2-center-end):not(.swal2-center-left):not(.swal2-center-right):not(.swal2-bottom):not(.swal2-bottom-start):not(.swal2-bottom-end):not(.swal2-bottom-left):not(.swal2-bottom-right) > .swal2-modal {
        margin: auto
    }

@media all and (-ms-high-contrast:none),(-ms-high-contrast:active) {
    .swal2-container .swal2-modal {
        margin: 0 !important
    }
}

.swal2-container.swal2-fade {
    transition: background-color .1s
}

.swal2-container.swal2-shown {
    background-color: rgba(0,0,0,.4)
}

.swal2-popup {
    display: none;
    position: relative;
    flex-direction: column;
    justify-content: center;
    width: 32em;
    max-width: 100%;
    padding: 1.25em;
    border-radius: .3125em;
    background: #fff;
    font-family: inherit;
    font-size: 1rem;
    box-sizing: border-box
}

    .swal2-popup:focus {
        outline: 0
    }

    .swal2-popup.swal2-loading {
        overflow-y: hidden
    }

    .swal2-popup .swal2-header {
        display: flex;
        flex-direction: column;
        align-items: center
    }

    .swal2-popup .swal2-title {
        display: block;
        position: relative;
        max-width: 100%;
        margin: 0 0 .4em;
        padding: 0;
        color: #595959;
        font-size: 1.875em;
        font-weight: 600;
        text-align: center;
        text-transform: none;
        word-wrap: break-word
    }

    .swal2-popup .swal2-actions {
        align-items: center;
        justify-content: center;
        margin: 1.25em auto 0
    }

        .swal2-popup .swal2-actions:not(.swal2-loading) .swal2-styled[disabled] {
            opacity: .4
        }

        .swal2-popup .swal2-actions:not(.swal2-loading) .swal2-styled:hover {
            background-image: linear-gradient(rgba(0,0,0,.1),rgba(0,0,0,.1))
        }

        .swal2-popup .swal2-actions:not(.swal2-loading) .swal2-styled:active {
            background-image: linear-gradient(rgba(0,0,0,.2),rgba(0,0,0,.2))
        }

        .swal2-popup .swal2-actions.swal2-loading .swal2-styled.swal2-confirm {
            width: 2.5em;
            height: 2.5em;
            margin: .46875em;
            padding: 0;
            border: .25em solid transparent;
            border-radius: 100%;
            border-color: transparent;
            background-color: transparent !important;
            color: transparent;
            cursor: default;
            box-sizing: border-box;
            -webkit-animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
            animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none
        }

        .swal2-popup .swal2-actions.swal2-loading .swal2-styled.swal2-cancel {
            margin-right: 30px;
            margin-left: 30px
        }

        .swal2-popup .swal2-actions.swal2-loading :not(.swal2-styled).swal2-confirm::after {
            display: inline-block;
            width: 15px;
            height: 15px;
            margin-left: 5px;
            border: 3px solid #999;
            border-radius: 50%;
            border-right-color: transparent;
            box-shadow: 1px 1px 1px #fff;
            content: '';
            -webkit-animation: swal2-rotate-loading 1.5s linear 0s infinite normal;
            animation: swal2-rotate-loading 1.5s linear 0s infinite normal
        }

    .swal2-popup .swal2-styled {
        margin: 0 .3125em;
        padding: .625em 2em;
        font-weight: 500;
        box-shadow: none
    }

        .swal2-popup .swal2-styled:not([disabled]) {
            cursor: pointer
        }

        .swal2-popup .swal2-styled.swal2-confirm {
            border: 0;
            border-radius: .25em;
            background: initial;
            background-color: #3085d6;
            color: #fff;
            font-size: 1.0625em
        }

        .swal2-popup .swal2-styled.swal2-cancel {
            border: 0;
            border-radius: .25em;
            background: initial;
            background-color: #aaa;
            color: #fff;
            font-size: 1.0625em
        }

        .swal2-popup .swal2-styled:focus {
            outline: 0;
            box-shadow: 0 0 0 2px #fff,0 0 0 4px rgba(50,100,150,.4)
        }

        .swal2-popup .swal2-styled::-moz-focus-inner {
            border: 0
        }

    .swal2-popup .swal2-footer {
        justify-content: center;
        margin: 1.25em 0 0;
        padding-top: 1em;
        border-top: 1px solid #eee;
        color: #545454;
        font-size: 1em
    }

    .swal2-popup .swal2-image {
        max-width: 100%;
        margin: 1.25em auto
    }

    .swal2-popup .swal2-close {
        position: absolute;
        top: 0;
        right: 0;
        justify-content: center;
        width: 1.2em;
        height: 1.2em;
        padding: 0;
        transition: color .1s ease-out;
        border: none;
        border-radius: 0;
        background: 0 0;
        color: #ccc;
        font-family: serif;
        font-size: 2.5em;
        line-height: 1.2;
        cursor: pointer;
        overflow: hidden
    }

        .swal2-popup .swal2-close:hover {
            -webkit-transform: none;
            transform: none;
            color: #f27474
        }

    .swal2-popup > .swal2-checkbox, .swal2-popup > .swal2-file, .swal2-popup > .swal2-input, .swal2-popup > .swal2-radio, .swal2-popup > .swal2-select, .swal2-popup > .swal2-textarea {
        display: none
    }

    .swal2-popup .swal2-content {
        justify-content: center;
        margin: 0;
        padding: 0;
        color: #545454;
        font-size: 1.125em;
        font-weight: 300;
        line-height: normal;
        word-wrap: break-word
    }

    .swal2-popup #swal2-content {
        text-align: center
    }

    .swal2-popup .swal2-checkbox, .swal2-popup .swal2-file, .swal2-popup .swal2-input, .swal2-popup .swal2-radio, .swal2-popup .swal2-select, .swal2-popup .swal2-textarea {
        margin: 1em auto
    }

    .swal2-popup .swal2-file, .swal2-popup .swal2-input, .swal2-popup .swal2-textarea {
        width: 100%;
        transition: border-color .3s,box-shadow .3s;
        border: 1px solid #d9d9d9;
        border-radius: .1875em;
        font-size: 1.125em;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.06);
        box-sizing: border-box
    }

        .swal2-popup .swal2-file.swal2-inputerror, .swal2-popup .swal2-input.swal2-inputerror, .swal2-popup .swal2-textarea.swal2-inputerror {
            border-color: #f27474 !important;
            box-shadow: 0 0 2px #f27474 !important
        }

        .swal2-popup .swal2-file:focus, .swal2-popup .swal2-input:focus, .swal2-popup .swal2-textarea:focus {
            border: 1px solid #b4dbed;
            outline: 0;
            box-shadow: 0 0 3px #c4e6f5
        }

        .swal2-popup .swal2-file::-webkit-input-placeholder, .swal2-popup .swal2-input::-webkit-input-placeholder, .swal2-popup .swal2-textarea::-webkit-input-placeholder {
            color: #ccc
        }

        .swal2-popup .swal2-file:-ms-input-placeholder, .swal2-popup .swal2-input:-ms-input-placeholder, .swal2-popup .swal2-textarea:-ms-input-placeholder {
            color: #ccc
        }

        .swal2-popup .swal2-file::-ms-input-placeholder, .swal2-popup .swal2-input::-ms-input-placeholder, .swal2-popup .swal2-textarea::-ms-input-placeholder {
            color: #ccc
        }

        .swal2-popup .swal2-file::placeholder, .swal2-popup .swal2-input::placeholder, .swal2-popup .swal2-textarea::placeholder {
            color: #ccc
        }

    .swal2-popup .swal2-range input {
        width: 80%
    }

    .swal2-popup .swal2-range output {
        width: 20%;
        font-weight: 600;
        text-align: center
    }

    .swal2-popup .swal2-range input, .swal2-popup .swal2-range output {
        height: 2.625em;
        margin: 1em auto;
        padding: 0;
        font-size: 1.125em;
        line-height: 2.625em
    }

    .swal2-popup .swal2-input {
        height: 2.625em;
        padding: .75em
    }

        .swal2-popup .swal2-input[type=number] {
            max-width: 10em
        }

    .swal2-popup .swal2-file {
        font-size: 1.125em
    }

    .swal2-popup .swal2-textarea {
        height: 6.75em;
        padding: .75em
    }

    .swal2-popup .swal2-select {
        min-width: 50%;
        max-width: 100%;
        padding: .375em .625em;
        color: #545454;
        font-size: 1.125em
    }

    .swal2-popup .swal2-checkbox, .swal2-popup .swal2-radio {
        align-items: center;
        justify-content: center
    }

        .swal2-popup .swal2-checkbox label, .swal2-popup .swal2-radio label {
            margin: 0 .6em;
            font-size: 1.125em
        }

        .swal2-popup .swal2-checkbox input, .swal2-popup .swal2-radio input {
            margin: 0 .4em
        }

    .swal2-popup .swal2-validationerror {
        display: none;
        align-items: center;
        justify-content: center;
        padding: .625em;
        background: #f0f0f0;
        color: #666;
        font-size: 1em;
        font-weight: 300;
        overflow: hidden
    }

        .swal2-popup .swal2-validationerror::before {
            display: inline-block;
            width: 1.5em;
            min-width: 1.5em;
            height: 1.5em;
            margin: 0 .625em;
            border-radius: 50%;
            background-color: #f27474;
            color: #fff;
            font-weight: 600;
            line-height: 1.5em;
            text-align: center;
            content: '!';
            zoom: normal
        }

@supports (-ms-accelerator:true) {
    .swal2-range input {
        width: 100% !important
    }

    .swal2-range output {
        display: none
    }
}

@media all and (-ms-high-contrast:none),(-ms-high-contrast:active) {
    .swal2-range input {
        width: 100% !important
    }

    .swal2-range output {
        display: none
    }
}

@-moz-document url-prefix() {
    .swal2-close:focus {
        outline: 2px solid rgba(50,100,150,.4)
    }
}

.swal2-icon {
    position: relative;
    justify-content: center;
    width: 5em;
    height: 5em;
    margin: 1.25em auto 1.875em;
    border: .25em solid transparent;
    border-radius: 50%;
    line-height: 5em;
    cursor: default;
    box-sizing: content-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    zoom: normal
}

.swal2-icon-text {
    font-size: 3.75em
}

.swal2-icon.swal2-error {
    border-color: #f27474
}

    .swal2-icon.swal2-error .swal2-x-mark {
        /*position: relative;*/
        flex-grow: 1
    }

    .swal2-icon.swal2-error [class^=swal2-x-mark-line] {
        display: block;
        position: absolute;
        top: 2.3125em;
        width: 2.9375em;
        height: .3125em;
        border-radius: .125em;
        background-color: #f27474
    }

        .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=left] {
            left: 1.0625em;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg)
        }

        .swal2-icon.swal2-error [class^=swal2-x-mark-line][class$=right] {
            right: 1em;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg)
        }

.swal2-icon.swal2-warning {
    border-color: #facea8;
    color: #f8bb86
}

.swal2-icon.swal2-info {
    border-color: #9de0f6;
    color: #3fc3ee
}

.swal2-icon.swal2-question {
    border-color: #c9dae1;
    color: #87adbd
}

.swal2-icon.swal2-success {
    border-color: #a5dc86
}

    .swal2-icon.swal2-success [class^=swal2-success-circular-line] {
        position: absolute;
        width: 3.75em;
        height: 7.5em;
        -webkit-transform: rotate(45deg);
        transform: rotate(45deg);
        border-radius: 50%
    }

        .swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=left] {
            top: -.4375em;
            left: -2.0635em;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            -webkit-transform-origin: 3.75em 3.75em;
            transform-origin: 3.75em 3.75em;
            border-radius: 7.5em 0 0 7.5em
        }

        .swal2-icon.swal2-success [class^=swal2-success-circular-line][class$=right] {
            top: -.6875em;
            left: 1.875em;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
            -webkit-transform-origin: 0 3.75em;
            transform-origin: 0 3.75em;
            border-radius: 0 7.5em 7.5em 0
        }

    .swal2-icon.swal2-success .swal2-success-ring {
        position: absolute;
        top: -.25em;
        left: -.25em;
        width: 100%;
        height: 100%;
        border: .25em solid rgba(165,220,134,.3);
        border-radius: 50%;
        z-index: 2;
        box-sizing: content-box
    }

    .swal2-icon.swal2-success .swal2-success-fix {
        position: absolute;
        top: .5em;
        left: 1.625em;
        width: .4375em;
        height: 5.625em;
        -webkit-transform: rotate(-45deg);
        transform: rotate(-45deg);
        z-index: 1
    }

    .swal2-icon.swal2-success [class^=swal2-success-line] {
        display: block;
        position: absolute;
        height: .3125em;
        border-radius: .125em;
        background-color: #a5dc86;
        z-index: 2
    }

        .swal2-icon.swal2-success [class^=swal2-success-line][class$=tip] {
            top: 2.875em;
            left: .875em;
            width: 1.5625em;
            -webkit-transform: rotate(45deg);
            transform: rotate(45deg)
        }

        .swal2-icon.swal2-success [class^=swal2-success-line][class$=long] {
            top: 2.375em;
            right: .5em;
            width: 2.9375em;
            -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg)
        }

.swal2-progresssteps {
    align-items: center;
    margin: 0 0 1.25em;
    padding: 0;
    font-weight: 600
}

    .swal2-progresssteps li {
        display: inline-block;
        position: relative
    }

    .swal2-progresssteps .swal2-progresscircle {
        width: 2em;
        height: 2em;
        border-radius: 2em;
        background: #3085d6;
        color: #fff;
        line-height: 2em;
        text-align: center;
        z-index: 20
    }

        .swal2-progresssteps .swal2-progresscircle:first-child {
            margin-left: 0
        }

        .swal2-progresssteps .swal2-progresscircle:last-child {
            margin-right: 0
        }

        .swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep {
            background: #3085d6
        }

            .swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progresscircle {
                background: #add8e6
            }

            .swal2-progresssteps .swal2-progresscircle.swal2-activeprogressstep ~ .swal2-progressline {
                background: #add8e6
            }

    .swal2-progresssteps .swal2-progressline {
        width: 2.5em;
        height: .4em;
        margin: 0 -1px;
        background: #3085d6;
        z-index: 10
    }

[class^=swal2] {
    -webkit-tap-highlight-color: transparent
}

.swal2-show {
    -webkit-animation: swal2-show .3s;
    animation: swal2-show .3s
}

    .swal2-show.swal2-noanimation {
        -webkit-animation: none;
        animation: none
    }

.swal2-hide {
    -webkit-animation: swal2-hide .15s forwards;
    animation: swal2-hide .15s forwards
}

    .swal2-hide.swal2-noanimation {
        -webkit-animation: none;
        animation: none
    }

[dir=rtl] .swal2-close {
    right: auto;
    left: 0
}

.swal2-animate-success-icon .swal2-success-line-tip {
    -webkit-animation: swal2-animate-success-line-tip .75s;
    animation: swal2-animate-success-line-tip .75s
}

.swal2-animate-success-icon .swal2-success-line-long {
    -webkit-animation: swal2-animate-success-line-long .75s;
    animation: swal2-animate-success-line-long .75s
}

.swal2-animate-success-icon .swal2-success-circular-line-right {
    -webkit-animation: swal2-rotate-success-circular-line 4.25s ease-in;
    animation: swal2-rotate-success-circular-line 4.25s ease-in
}

.swal2-animate-error-icon {
    -webkit-animation: swal2-animate-error-icon .5s;
    animation: swal2-animate-error-icon .5s
}

    .swal2-animate-error-icon .swal2-x-mark {
        -webkit-animation: swal2-animate-error-x-mark .5s;
        animation: swal2-animate-error-x-mark .5s
    }

@-webkit-keyframes swal2-rotate-loading {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}

@keyframes swal2-rotate-loading {
    0% {
        -webkit-transform: rotate(0);
        transform: rotate(0)
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg)
    }
}


.dispnone{
    display:none;
}


.modal {
    font-size: 12px;
}

    .modal > .header {
        width: 100%;
        border-bottom: 1px solid gray;
        font-size: 18px;
        text-align: center;
        padding: 5px;
    }

    .modal > .content {
        width: 100%;
        padding: 10px 5px;
    }

    .modal > .actions {
        width: 100%;
        padding: 10px 5px;
        margin: auto;
        text-align: center;
    }

    .modal > .close {
        cursor: pointer;
        position: absolute;
        display: block;
        padding: 2px 5px;
        line-height: 20px;
        right: -10px;
        top: -10px;
        font-size: 24px;
        background: #ffffff;
        border-radius: 18px;
        border: 1px solid #cfcece;
    }

.bookreaderepub {
    position: relative;
    min-height: 400px;
    height: 600px;
    max-height: 1000px;
    width: 100%
}

.react-jinke-music-player-mobile {
    z-index: 3000 !important;
}
.audio-lists-panel {
    z-index: 3001 !important;
}

/* Social */
.social {
    list-style: none;
    margin: 0;
    padding: 0;
    text-align: left;
    margin: 30px 0px 30px 0px;
}

    .social li {
        display: inline-block;
        margin: 0 30px 0 0;
        border: 1px solid #fe0072;
        padding: 10px;
        width: 50px;
        height: 45px;
    }

        .social li:hover {
            display: inline-block;
            margin: 0 30px 0 0;
            border: 1px solid #fff;
            padding: 10px;
            width: 50px;
            height: 45px;
            color: #fff !important;
            background: #fe0072;
            -webkit-transition: all .3s;
            -moz-transition: all .3s;
            transition: all .3s;
        }

        .social li:last-child {
            margin-right: 0;
        }

        .social li a {
            display: block;
            overflow: hidden;
            padding: 0;
            color: #fe0072;
            -webkit-transition: all .3s;
            -moz-transition: all .3s;
            transition: all .3s;
        }

            .social li a:hover {
                display: block;
                overflow: hidden;
                padding: 0;
                color: #fff;
                -webkit-transition: all .3s;
                -moz-transition: all .3s;
                transition: all .3s;
            }

        .social li i {
            position: relative;
            display: block;
            font-size: 18px;
            line-height: 20px;
            cursor: pointer;
            overflow: hidden;
            text-align: center;
        }



.bookPartner {
    color: #fff;
    background: #08a302;
    height: 30px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
}

.bookSales {
    color: #fff;
    background: #08a302;
    height: 30px;
    height: 30px;
    
    line-height: 30px;
    text-align: center;
    text-transform: uppercase;
}

.oldPrice {
    color: #3c3c3c;
    text-decoration: line-through !important;
    padding-left: 5px !important;
}

.checkout-my {
    font-size: 14px;
    border: 1px solid #ff0072;
}
.checkout-text-my {
    font-size: 12px;
}
.checkout-head-my {
    font-weight: 300;
    font-size: 20px;
    padding-bottom: 4px;
    
    color: #555;
}


.checkout-head {
    font-weight: 300;
    font-size: 20px;
    /* padding-bottom: 4px; */
    /* border-bottom: 1px solid #555; */
    color: #fff;
    background: #ff0072;
    padding: 10px;
}


@media (max-width: 480px ) {
    .ql-video {
        display: block;
        max-width: 100%;
        width: 100%;
        height: 200px;
    }
}
@media (min-width: 481px ) {
    .ql-video {
        display: block;
        max-width: 100%;
        width: 100%;
        height: 500px;
    }
}
.control-label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
    font-size: 14px;
    color: #555;
    text-transform: uppercase;
    display: block;
    margin-bottom: 5px
}
/* Position and sizing of burger button */
.bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 36px;
    top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
    background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
    position: fixed;
    height: 100%;
}

/* General sidebar styles */
.bm-menu {
    background: #373a47;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
    fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
}

/* Individual item */
.bm-item {
    display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
    background: rgba(0, 0, 0, 0.3);
}

.navbar-light .navbar-toggler{display:none !important;}
.burger-menu-btn{display:flex}
.hamburger-react{float:left}
.burger-menu-btn a { padding-left: 0px !important;}
.burger-menu-btn span {
    line-height: 5rem;
    height: 5rem;
}
.burger-nav a {
    color:#fff;
    text-transform:uppercase;
    font-size:16px;
    

}
.btn-bk{
    font-size:10px !important;

}
.btn-green {
    background: #068700;
    color: #fff;
}

.btn-red {
    background: #ff0072;
    color: #fff;
    border: 1px solid #ff0072;
}

    .btn-red:hover {
        background: #fff;
        color: #ff0072;
    }

    .bi-mob{
        font-size:20px
    }


.btn-green:hover {
    background: #fff;
    color: #068700;
}
.btn-add-size {
    line-height: 3.5rem;
    height: 3.5rem;
}
.bi-inline {
    display: inline-grid;
}

.text-time {
    font-size: 10px;
    text-transform: uppercase;
}
.burger-nav li {
    list-style-type: none;
    margin-bottom: 10px
}
.burger-nav ul {
    margin-left: 0;
    padding-left: 0;
}

.list-price {
    padding-left: 20px;
    color: #ff0072 !important;
}
.lang-nav{
    border-bottom:1px solid #fff;
    margin-bottom:10px;
    
    font-size:12px;
}
.lang-nav li {
    display: table-cell; /* ���������� ��� �������� ������� */

    margin: 0;
    padding-right: 15px;
}
.rusbtn {
    text-transform:uppercase;
}
.tatbtn {
    font-size: 12px !important;
}
    .tatbtn img {
        width: 25px;
        margin: 0 5px 0 0;
        vertical-align: middle;
    }

.lang-nav a {
    cursor: alias;
}
.burger-nav i{
    padding-right:10px;
}