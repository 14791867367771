.mainbox {
    background-color: #95c2de;
    margin: auto;
    min-height: 300px;
    min-width: 200px;
    max-width:100%;
    max-height:100%;
    position: relative;
    text-align:center;
}

.err {
    color: #ffffff;
    font-family: 'Segoe UI';
    font-size: 11rem;
    text-align: center;
    padding:50px 20px 20px 20px;
}

.far {
    position: absolute;
    font-size: 8.5rem;
    left: 42%;
    top: 15%;
    color: #ffffff;
}

.err2 {
    color: #ffffff;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position: absolute;
    left: 68%;
    top: 8%;
}

.msg {
    text-align: center;
    font-family: 'Segoe UI';
    font-size: 4.5rem;    
    padding: 50px;
    color:#fff;
}
    .msg p {
        font-size: 2rem;
    }

